import React from "react";
import styled from "styled-components";
import Logo from "../../../assets/img/fbi/logo.png";
import LinkBg from "../../../assets/img/coin/Link.png";
const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  flex: 0 0 35%; /* 50% width for desktop */
  padding: 20px;
`;

const ContentContainer = styled.div`
  flex: 0 0 65%; /* 50% width for desktop */
  padding: 20px;
  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

const Heading = styled.h1`
  font-size: 76px;
  font-family: tahu;
  line-height: 100px;
  @media screen and (max-width: 600px) {
    font-size: 62px;
    line-height: 60px;
  }
`;

const SecondaryHeading = styled.h2`
  font-size: 42px;
  font-family: tahu;
  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
`;

const Button = styled.button`
  font-size: 16px;
  padding: 10px 20px;
`;

const HeroHeader = () => {
  return (
    <Container>
      <LogoContainer>
        {/* Your logo or image goes here */}
        <img src={Logo} alt="Logo" />
      </LogoContainer>
      <ContentContainer>
        <Heading>The Legend of The Crazy Hawaiian</Heading>
        <Paragraph>
          In the heart of the Hawaiian Islands, on the beaches of Oahu, lived a
          young man named Koa. Inspired by his ancestors and the spirit of
          'mana,' Koa became known for his strength and charisma, eventually
          earning the title
        </Paragraph>
        <SecondaryHeading>"The Crazy Hawaiian."</SecondaryHeading>
        <Paragraph>
          Starting from local competitions, Koa mastered the art of the power
          slap, drawing on ancient warrior traditions. His fame spread quickly,
          echoing the resilient spirit of the islands. Faced with global
          challenges, Koa saw opportunity in the rising tide of cryptocurrency.
          Thus, Da Crazy Hawaiian MemeCoin was born, a playful yet powerful
          digital embodiment of his journey. With no transaction fees and robust
          stability, this memecoin rapidly attracted crypto enthusiasts
          worldwide. As Koa conquered power slap competitions, eventually
          becoming the Heavyweight Slap King, Da Crazy Hawaiian MemeCoin surged
          alongside his legendary status. Today, the story of The Crazy Hawaiian
          and his MemeCoin inspires many, proving that humble beginnings can
          make waves of change and joy globally.
        </Paragraph>
      </ContentContainer>
    </Container>
  );
};

export default HeroHeader;
