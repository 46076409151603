import { NavLink } from "react-router-dom";
import first from "../../../assets/img/hero-header/coin.png";
import second from "../../../assets/img/hero-header/ordinal.png";

// import third from "../../../assets/img/hero-header/NFT.png";
import third from "../../../assets/img/hero-header/new NFT graphic.png";
import top from "../../../assets/img/daCrazy/heroheader.svg";
import top1 from "../../../assets/img/daCrazy/heroheader2.svg";
import coinUnderline from "../../../assets/img/hero-header/coinUnderline.png";
const WelcomeArea = ({ data }) => {
  return (
    <section
      className="hero-section-home"
      id="home"
      // style={{ border: "1px solid red" }}
    >
      {/* Hero Content */}
      <div className="app">
        <div className="row align-items-center">
          {/* Welcome Content */}
          <div className="col-12 col-lg-12 col-md-12">
            <div className="text-center" style={{ marginTop: "200px" }}>
              {/* Hero Content */}
              <div className="mb-50 mt-9">
                <img
                  src={top1}
                  alt="Rectangular Image"
                  className="mx-auto mb-4"
                />
                <img
                  src={top}
                  alt="Rectangular Image"
                  height={550}
                  className="mx-auto mb-4"
                />
              </div>
              {/* Cards Section */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeArea;
