import React from "react";
import styled from "styled-components";
import Logo from "../../assets/img/fbi/logo.png";
import { useHistory } from "react-router-dom";
const Container = styled.div`
  max-width: 1240px;
  margin: 100px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  flex: 0 0 20%; /* 50% width for desktop */
  padding: 20px;
`;

const ContentContainer = styled.div`
  flex: 0 0 65%; /* 50% width for desktop */
  padding: 20px;
  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

const Heading = styled.h1`
  font-size: 62px;
  font-family: tahu;
  line-height: 100px;
  @media screen and (max-width: 600px) {
    font-size: 62px;
    line-height: 60px;
  }
`;

const SecondaryHeading = styled.h2`
  font-size: 42px;
  font-family: tahu;
  @media screen and (max-width: 600px) {
    font-size: 32px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
`;

const Button = styled.button`
  font-size: 16px;
  padding: 10px 20px;
`;

const DaCrazyAbout = () => {
  const navigate = useHistory();
  return (
    <Container>
      <LogoContainer>
        {/* Your logo or image goes here */}
        <img src={Logo} alt="Logo" />
      </LogoContainer>
      <ContentContainer>
        <Heading>About Da Crazy Hawaiian</Heading>
        <Paragraph>
          In "Da Crazy Hawaiian", real name Koa Viernes, is known as one badass
          dude who can both give and take slaps better than just about anybody.
          Mr. Hawaiian started slap fighting with the SlapFIGHT Championship
          league in the United States.
        </Paragraph>
        <button
          className="btn more-btn black-btn"
          onClick={() => (window.location.href = "/")}
        >
          Get Started
        </button>
      </ContentContainer>
    </Container>
  );
};

export default DaCrazyAbout;
