import SectionHeading from "../SectionHeading";
import NewsContent from "./NewsContent";
import arrow from "../../assets/img/daCrazy/arrows.png";
import phase1 from "../../assets/img/daCrazy/phase_1.svg";
import phase2 from "../../assets/img/daCrazy/phase_2.svg";
import phase3 from "../../assets/img/daCrazy/phase_3.svg";
import phase4 from "../../assets/img/daCrazy/phase_4.svg";

import smallimg from "../../assets/img/daCrazy/daCraztcoinlogo.svg";
const OurRoadmap = ({ data }) => {
  return (
    <section
      className="roadmap section-padding-100-0"
      style={{ marginTop: 200 }}
      id="roadmap"
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={arrow} alt="" />
      </div>
      <SectionHeading
        ClassTitle="gradient-text"
        title={"Project Roadmap"}
        text={
          "Charting the Course: Roadmap to Unraveling Satoshi's Digital Odyssey"
        }
      />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="timeline">
              <div className="date-title">
                <span>{data.roadmap.title}</span>
              </div>

              <div className="row">
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item"
                  title=""
                  ClassNewsMediaBx="news-media"
                  ClassNewsMediaLink="colorbox cboxElement"
                  img={smallimg}
                  // largeImg="/img/core-img/phase1.svg"
                  largeImg={phase1}
                  // text={item.text}
                />

                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item right"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  img={smallimg}
                  // largeImg="/img/core-img/phase2.svg"
                  largeImg={phase2}
                  addCboxElementLink={true}
                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item"
                  title=""
                  ClassNewsMediaBx="news-media video"
                  ClassNewsMediaLink="colorbox-video cboxElement"
                  largeImg={phase3}
                  // img={item.img}
                  img={smallimg}

                  // text={item.text}
                />
                <NewsContent
                  ClassItem="col-sm-12 col-md-6 news-item right"
                  title=""
                  ClassNewsMediaBx="news-media gallery"
                  ClassNewsMediaLink="colorbox cboxElement"
                  largeImg={phase4}
                  // img={item.img}
                  img={smallimg}
                  addCboxElementLink={true}
                  // text={item.text}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurRoadmap;
