import React, { useState } from "react";
import data from "../../data/static.json";

import Breadcumb from "../../components/Breadcumb";
import AboutOurCollection from "../../components/AboutOurCollection";
import Subscribe from "../../components/Subscribe";
import AboutImg from "../../assets/img/collection/WIBS about graphic 1.png";
import RightBg from "../../assets/img/collection/left-bg-1.png";
import LeftBg from "../../assets/img/collection/right-bg-1.png";

import twitter from "../../assets/img/icons/X white.svg";
import facebook from "../../assets/img/daCrazy/fb_icon.png";

import telegram from "../../assets/img/icons/telegram.svg";
import TeamArea from "../../components/TeamArea";
import UnveilingSatoshi from "../../components/Unveiling";
import Modal from "../../components/Modal";
import instagramIcon from "../../assets/img/daCrazy/insta_icon.png";
import DaCrazyAbout from "../../components/daCrazyAbout";

import crazyYoutube from "../../assets/img/daCrazy/daCrazyYoutubeImg.png";
import youtubeIcon from "../../assets/img/daCrazy/youtubeIcon.png";
import rightBg from "../../assets/img/daCrazy/right_bg_arrow_down.png";
import leftBg from "../../assets/img/daCrazy/left_bg_1.png";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
const AboutContainer = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const handleTwitter = () => {
    history("/");
  };

  const handleyoutubeIcon = () => {
    window.open("https://www.youtube.com/watch?v=3e7YBRwLf24", "_blank");
  };

  return (
    <>
      <Breadcumb title="About us" text="Home" />
      <div
        style={{
          position: "relative",
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {/* <div style={{ position: "absolute", left: 0, top: 300 }}>
          <img
            className="about-us-img"
            src={RightBg}
            alt=""
            style={{ width: "270px" }}
          />
        </div> */}
        {/* 
        <div style={{ position: "absolute", right: 0, bottom: -500 }}>
          <img
            className="about-us-img"
            src={LeftBg}
            alt=""
            style={{ width: "200px" }}
          />
        </div> */}
        <div
          style={{
            position: "relative",
            maxWidth: "1440px",
            width: "100%",
            margin: "100px auto",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={crazyYoutube} alt="" />
          <img
            src={youtubeIcon}
            className="crazyYoutubehover"
            alt=""
            onClick={handleyoutubeIcon}
          />
        </div>

        <div style={{ marginTop: "100px" }}></div>

        <div
          style={{
            maxWidth: "1980px",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
          }}
        >
          {!isMob && (
            <>
              <div
                style={{
                  position: "absolute",
                  right: 50,

                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img src={rightBg} style={{ width: "65%" }} />
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: 1000,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <img src={leftBg} style={{ width: "70%" }} />
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 2800,

                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* <img src={rightBg2} style={{ width: "60%" }} /> */}
              </div>
            </>
          )}
          <UnveilingSatoshi isAbout />
          <DaCrazyAbout isAbout />
        </div>

        <div className="container" style={{ marginTop: "100px" }}>
          <div className="row text-center d-flex justify-content-center">
            <div className="col-12 col-lg-4 col-md-6 ">
              <div className="text-center">
                {/* Copywrite */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                  className=" social-img"
                  data-wow-delay="0.4s"
                >
                  Follow us at :
                  <a
                    href="https://x.com/dacrazyhawaiian?lang=en"
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                  >
                    <img
                      src={twitter}
                      alt="Twitter"
                      width="53px"
                      style={{ filter: "invert(1)" }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/da_crazy_hawaiian/?hl=en"
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                  >
                    <img src={instagramIcon} alt="Twitter" width="55px" />
                  </a>
                  <a
                    href="https://www.facebook.com/DACRAZYHAWAIIANSLAPCHAMP/"
                    style={{ marginLeft: "10px" }}
                    target="_blank"
                  >
                    <img src={facebook} alt="Twitter" width="55px" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "300px" }}></div>
        <TeamArea data={data[0].TeamArea} setShow={setShow} />
      </div>

      <div style={{ marginBottom: "300px" }}></div>
      <Modal show={show} onClose={() => setShow(false)} />
    </>
  );
};

export default AboutContainer;
