import EventContainer from "../containers/Event";
import Layout from "../layouts";

const EventPage = () => {
  return (
    <Layout Title="Event">
      <EventContainer />
    </Layout>
  );
};

export default EventPage;
