import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import {
  injected,
  walletconnect,
  coinbaseWallet,
  switchNetwork,
} from "../../../smartcontract/walletConnect.js";
import MetaMaskLogo from "../../../assets/img/wallet-images/metamask.png";
import WalletConnectLogo from "../../../assets/img/wallet-images/walletconnect.png";
import CoinbaseLogo from "../../../assets/img/wallet-images/coinbase.png";
import { ethers } from "ethers";
import { addTokenToMetaMask } from "../../../smartcontract/web3Service/web3Service.js";

const ConnectModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; // This will center the modal content vertically
  z-index: 1000; /* Ensure the modal is above other content */
`;

const ModalContent = styled.div`
  background: #eeee; /* Change the background color */
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  z-index: 1001;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  h3,
  p {
    color: black; /* Ensure the text color is black */
  }
`;

const Button = styled.button`
  margin-top: 10px;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

// Reusing the style of BuyNowButton from your existing setup
const ConnectButton = styled(Button)`
  background: black;
  color: #fff;
  border-radius: 5px;
  &:hover {
    background: #7e7e7e;
    border: 1px solid #7e7e7e;
  }
`;

const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const InstallModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const InstallModalContent = styled.div`
  background: #eeeeee; 
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  z-index: 1001;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  h3,
  p {
    color: black; /* Ensure the text color is black */
  }
`;

const WalletButtons = ({ setProvider, onConnect }) => {
  const { activate, account, connector, library, chainId } = useWeb3React();
  const [ethProvider, setEthProvider] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false); // Cleanup function to set the flag to false when component unmounts
    };
  }, []);

  const connectWallet = async (connector) => {
    try {
      if (connector === injected && !window.ethereum) {
        setShowInstallModal(true);
        return;
      }

      await activate(connector);
      if (window.ethereum) {
        const ethProviderInstance = new ethers.providers.Web3Provider(
          window.ethereum
        );
        if (isMounted) {
          setEthProvider(ethProviderInstance);
          setProvider(ethProviderInstance);
          if (connector === injected) {
            await addTokenToMetaMask(); // Call importToken after setting the provider if MetaMask
          } else {
            alert(
              "To add WIBS token, please manually add the token using the contract address: 0xa716004BCD80247e5b9E5E77F8fd4af87B85f1c1"
            );
          }
          setShowModal(false);
          onConnect();
        }
      }
    } catch (error) {
      console.error("Error on connecting: ", error);
    }
  };

  const handleSwitchNetwork = async () => {
    if (ethProvider && chainId !== 1) {
      try {
        await switchNetwork(ethProvider.provider);
      } catch (error) {
        console.error("Error switching network: ", error);
      }
    }
  };

  useEffect(() => {
    if (ethProvider) {
      handleSwitchNetwork();
    }
  }, [ethProvider, chainId]);

  return (
    <>
      <ConnectButton onClick={() => setShowModal(false)}>
        Connect Wallet(Coming soon...)
      </ConnectButton>
      {showModal && (
        <ConnectModal>
          <ModalContent>
            <h3>Select Wallet</h3>
            <ConnectButton onClick={() => connectWallet(injected)}>
              <Logo src={MetaMaskLogo} alt="MetaMask" /> MetaMask
            </ConnectButton>
            <ConnectButton onClick={() => connectWallet(walletconnect)}>
              <Logo src={WalletConnectLogo} alt="WalletConnect" /> WalletConnect
            </ConnectButton>
            <ConnectButton onClick={() => connectWallet(coinbaseWallet)}>
              <Logo src={CoinbaseLogo} alt="Coinbase Wallet" /> Coinbase Wallet
            </ConnectButton>
            <ConnectButton onClick={() => setShowModal(false)}>
              Cancel
            </ConnectButton>
          </ModalContent>
        </ConnectModal>
      )}
      {showInstallModal && (
        <InstallModal>
          <InstallModalContent>
            <h3>Install MetaMask</h3>
            <p>
              To connect with MetaMask, you need to install the MetaMask
              extension.
            </p>
            <ConnectButton
              onClick={() =>
                window.open("https://metamask.io/download.html", "_blank")
              }
            >
              Install MetaMask
            </ConnectButton>
            <ConnectButton onClick={() => setShowInstallModal(false)}>
              Cancel
            </ConnectButton>
          </InstallModalContent>
        </InstallModal>
      )}
    </>
  );
};

export default WalletButtons;
