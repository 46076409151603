import React, { useContext, useEffect, useRef, useState } from "react";
import CrossIcon from "../../assets/img/header/cross";
import Cross from "../../assets/img/cross_white.png";
import Logo from "../../assets/img/header/new indexx.ai logo.svg";
import "./styles.css";
// import { usePathname } from "next/navigation";
import { useLocation, Link } from "react-router-dom";

// import { Poppins } from "@next/font/google";
// import Link from "next/link";
// import Image from "next/image";

// const poppins = Poppins({
//   subsets: ["latin"],
//   display: "swap",
//   variable: "--font-poppins",
//   weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
// });

const poppins = {};

import Data from "./data";
import { useMediaQuery } from "react-responsive";
const HeaderTest = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const { pathname } = useLocation();
  //   const pathname = usePathname();
  // const themeData = useContext(Theme);
  const elementRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [headerData, setHeaderData] = useState(Data);
  const [backdropVisibility, setBackdropVisibility] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [activeIndex, setactiveIndex] = useState(0);
  // useEffect(() => {
  //   if (themeData?.theme) {
  //     setTheme(themeData?.theme);
  //   }
  // }, [themeData?.theme]);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    const email = localStorage.getItem("email"); // Assuming you also store email
    setIsLoggedIn(!!accessToken && !!refreshToken && !!email);
  }, []);

  const handleLogin = (isLogin) => {
    if (!isLogin) return;

    setModalShow(true);
  };
  const handleLogout = (isLogout) => {
    if (!isLogout) return;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("email");
    localStorage.removeItem("userType");
    setIsAuthenticated(false);
    setIsLoggedIn(false);
  };
  const handleItemClick = (path, i) => {
    setactiveIndex(i);
    window.location.href = path;
  };
  const handleMouseEnter = () => {
    // Set a timeout to open the dropdown after 0.2s
    timeoutRef.current = setTimeout(() => {
      setBackdropVisibility(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    // Clear the timeout if user leaves before 0.2s
    clearTimeout(timeoutRef.current);
    // Hide the dropdown after a delay
    setTimeout(() => {
      setBackdropVisibility(false);
    }, 200);
  };
  const updateBackDropVisibility = (type) => {
    if (type === "enter") handleMouseEnter();
    if (type === "leave") handleMouseLeave();
  };

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      const computedStyle = window.getComputedStyle(element);
      const visibility = computedStyle.getPropertyValue("visibility");
      const display = computedStyle.getPropertyValue("display");

      if (visibility === "hidden" || display === "none") {
        console.log("Element is hidden or not visible");
      } else {
        console.log("Element is visible");
        setBackdropVisibility(false);
      }
    }
  }, []);
  return (
    <>
      <nav
        style={{ position: "fixed", top: 0, left: 0, zIndex: 10000 }}
        className={poppins.className}
      >
        <div className="wrapper">
          {!isMob ? (
            <div
              className="backdrop"
              style={{
                display: "block",
                opacity: backdropVisibility ? 1 : 0,
                background: theme === "dark" ? "rgba(0,0,0,0.5)" : "",
                transitionDelay: ".1s",
                height: backdropVisibility ? "100vh" : 0,
              }}
            ></div>
          ) : (
            ""
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",

              flex: 1,
            }}
          >
            <div className="logo" style={{ marginRight: "30px" }}>
              <a href="https://indexx.ai" style={{ display: "flex" }}>
                <img src={Logo} alt="" />
              </a>
            </div>
            <input type="radio" name="slider" id="menu-btn" />
            <input type="radio" name="slider" id="close-btn" />
            <ul className="nav-links" style={{ flex: 1 }}>
              <label htmlFor="close-btn" className="btn close-btn">
                <img src={Cross} />
              </label>
              {headerData.map((element) => (
                <>
                  <li
                    className="main"
                    onMouseEnter={() => updateBackDropVisibility("enter")}
                    onMouseLeave={() => updateBackDropVisibility("leave")}
                  >
                    <a
                      href={element.href}
                      className={`desktop-item ${
                        element.active ? "link_active" : ""
                      } ${poppins.className}`}
                      onMouseEnter={() =>
                        updateBackDropVisibility(
                          "enter",
                          element.hasMegaDrop ? true : false
                        )
                      }
                    >
                      {element.mainTextDesktop}
                    </a>
                    <input type="checkbox" id={element.mainTextDesktop} />
                    <label
                      htmlFor={element.mainTextDesktop}
                      className={`mobile-item ${poppins.className}`}
                    >
                      {element.mainTextMob} {element.hasMegaDrop ? ">" : ""}
                    </label>

                    {element.hasMegaDrop ? (
                      <div
                        className="mega-box"
                        ref={elementRef}
                        style={{
                          background: theme === "light" ? "#FAFAFC" : "",
                          color:
                            theme === "light" ? "#333336 !important" : "white",
                        }}
                      >
                        <div className="content" style={{ textAlign: "left" }}>
                          {element.dropDownContent.map((elem, i) => (
                            <div
                              key={i}
                              className="row"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <header
                                style={{
                                  color:
                                    theme === "light"
                                      ? "#A0A0A5 !important"
                                      : "",
                                }}
                                className={`${poppins.className}`}
                              >
                                {elem.heading}
                              </header>
                              <ul
                                className={`mega-links ${
                                  elem.mainList ? "main" : ""
                                }`}
                              >
                                {elem.links.map((el, i) => (
                                  <li
                                    key={i}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",

                                      textAlign: "left",
                                    }}
                                    className="profile-list-item"
                                  >
                                    <a
                                      href={el.href}
                                      style={{ color: "white" }}
                                      className={` ${poppins.className}`}
                                    >
                                      {el.name}
                                    </a>
                                    <div
                                      className={`profile-inner-item-border-bottom `}
                                      style={{
                                        background: "#11BE6A",
                                        width: "16px",
                                        height: "3px",
                                        marginTop: "-5px",
                                      }}
                                    ></div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}

                          <div className="row"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {element.hasDrop ? (
                      <ul class="drop-menu">
                        <li>
                          <a href="#">Drop menu 1</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 2</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 3</a>
                        </li>
                        <li>
                          <a href="#">Drop menu 4</a>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <DarkMode /> */}
            <label htmlFor="menu-btn" className="btn menu-btn">
              <CrossIcon />
            </label>
          </div>
        </div>
      </nav>
      {/* {headerData.find(
        (el) => el.active === true && el.hasMegaDrop === true
      ) ? (
        <div
          className="secondary_header_root"
          style={{ background: theme === "dark" ? "#000" : "" }}
        >
          <div className="secondary_header_content">
            {headerData
              .find((el) => el.active === true)
              ?.dropDownContent.find((elem) => elem.mainList === true)
              ?.links?.map((curr, i) => (
                <div
                  key={i}
                  className={`secondary_header_content_item ${
                    i === activeIndex ? "active" : ""
                  }`}
                  onClick={() => handleItemClick(curr.href, i)}
                >
                  <span className="secondary_header_content_img_container">
                    <img
                      src={theme === "light" ? curr?.imgLight : curr?.imgDark}
                    />
                  </span>
                  <span>{curr.name}</span>
                </div>
              ))}
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default HeaderTest;
