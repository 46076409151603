import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import DiscoverOurNewCollection from "../../components/DiscoverOurNewCollection";
import HowItWorks from "../../components/HowItWorks";
import OurBrands from "../../components/OurBrands";
import OurRoadmap from "../../components/CoinRoadMap";
import FAQ from "../../components/FAQ";
import data from "../../data/static.json";
import WelcomeArea from "./WelcomeArea";
import TeamArea from "../../components/TeamArea";
import Subscribe from "../../components/Subscribe";
import ContactArea from "../../components/ContactArea";
import AboutSatoshi from "../../components/AboutSatoshiCoin";
import Tokenomics from "../../components/Tokenomics";
import Text from "../../assets/img/coin/text-part.svg";
import LinkBg from "../../assets/img/coin/Link.png";
import { useMediaQuery } from "react-responsive";
import leftBg from "../../assets/img/daCrazy/left_bg_1.png";
import rightBg from "../../assets/img/daCrazy/right_bg_1.png";
import whitePaperIcon from "../../assets/img/coin/whitepaper.svg";
import aboutIcon from "../../assets/img/coin/about.svg";

import "./style.css";
import TokenRoadMap from "../../components/TokenRoadMap";
const HomeDemo2Container = () => {
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "850px",
          margin: "auto",
          marginTop: "150px",
          display: "flex",
          justifyContent: "space-evenly",
          padding: "20px",
          flexWrap: "wrap",
          textAlign: "center",
        }}
      >
        <div style={{ minWidth: "150px", cursor: "pointer" }}>
          <a href="https://smallpdf.com/file#s=003d4e75-61a3-45ed-89a9-9f4f99e0f333">
            <img
              src={whitePaperIcon}
              style={{ height: "50px", filter: "invert(1)" }}
              className="hover-img"
            />
            <p>Whitepaper</p>
          </a>
        </div>

        <div style={{ minWidth: "150px", cursor: "pointer" }}>
          <a href="/about-us">
            <img
              src={aboutIcon}
              style={{ height: "50px", filter: "invert(1)" }}
              className="hover-img"
            />
            <p>About Coin</p>
          </a>
        </div>
      </div>
      <WelcomeArea data={data[0].HomeDemo2.WelcomeArea} />
      <div className="clearfix" />

      <div
        style={{
          maxWidth: "1980px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        {!isMob && (
          <>
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,

                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={rightBg} style={{ width: "80%" }} />
            </div>
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 700,

                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <img src={leftBg} style={{ width: "70%" }} />
            </div>
          </>
        )}

        <AboutSatoshi data={data} />
        <div style={{ marginTop: "150px" }}></div>

        <TokenRoadMap />
        <Tokenomics />

        <div style={{ marginTop: "200px" }}></div>

        <Subscribe data={data[0].Subscribe} />
        <div style={{ marginTop: "350px" }}></div>

        <ContactArea data={data[0].ContactArea} />
        <div style={{ marginTop: "250px" }}></div>
      </div>
    </>
  );
};

export default HomeDemo2Container;
