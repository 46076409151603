import React from "react";
import styled from "styled-components";
import tokenimg from "../../assets/img/daCrazy/token_roadmap.svg";
const Container = styled.div`
  max-width: 1040px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 990px) {
    text-align: center;
  }
`;

const Heading = styled.h1`
  font-size: 74px;
  font-family: tahu;
  line-height: 100px;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 62px;
    line-height: 60px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  text-align: center;
`;

const TokenRoadMap = () => {
  return (
    <Container>
      <ContentContainer>
        <Heading>Meme Coin Roadmap</Heading>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
          accumsan nisi Ut ut felis congue nisl hendrerit commodo.
        </Paragraph>
        <img src={tokenimg} alt="" style={{ marginTop: "100px" }} />
      </ContentContainer>
    </Container>
  );
};

export default TokenRoadMap;
