const SectionHeading = ({
  ClassTitle = "",
  title,
  otherTitle,
  text,
  addWhite = false,
  isFirstHead = false,
  isFontShort = false,
  btnColor,
}) => {
  return (
    <div className="section-heading text-center">
      {/* Dream Dots */}
      <div className="dream-dots justify-content-center" data-aos="fade-up">
        {/* {isFirstHead && <span className={ClassTitle}>{title}</span>} */}
      </div>
      <h2
        data-aos="fade-up"
        style={{
          fontFamily: "tahu, sans-serif",
          fontSize: isFontShort ? "60px" : "82px",
        }}
      >
        {otherTitle ? otherTitle : title}
      </h2>
      <p data-aos="fade-up">{text}</p>
    </div>
  );
};

export default SectionHeading;
