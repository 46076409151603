import React from "react";
import Logo from "../../../assets/img/coin/Coin-logo.svg";
import Text from "../../../assets/img/coin/text-part.svg";
import LinkBg from "../../../assets/img/coin/Link.png";
import top from "../../../assets/img/coin/top.svg";
import infoLogo from "../../../assets/img/coin/info.svg";
import { useMediaQuery } from "react-responsive";
import CoinForm from "../CoinForm/index";
import Backdrop from "../BackdropModal";
import coinHeroHeaderImg from "../../../assets/img/daCrazy/coin_heroheader_left.svg";

import "./style.css";
const WelcomeArea = ({ data }) => {
  const isMob = useMediaQuery({
    query: "(max-width: 990px)",
  });

  const [url, setURL] = React.useState("");

  const handleButtonClick = () => {
    const selectedUrl = url;
    window.open(selectedUrl, "_blank");
  };

  const handleCloseBtn = () => {
    setURL("");
  };
  const handleCallback = (Url) => {
    setURL(Url);
  };
  return (
    <>
      <section class="hero-section  section-padding" id="home">
        {/* Hero Content */}
        <div
          className="coin-hero-header-root
        "
        >
          <div>
            <img src={coinHeroHeaderImg} alt="" style={{ width: "80%" }} />
          </div>
          <div>
            <CoinForm setParentCB={handleCallback} />
          </div>
        </div>
      </section>
      {url ? (
        <Backdrop
          logoSrc={infoLogo}
          onCloseClick={handleCloseBtn}
          onClick={handleButtonClick}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default WelcomeArea;
