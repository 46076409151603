import React, { useState } from "react";
import SectionHeading from "../SectionHeading";
import twitter from "../../assets/img/icons/X white.svg";
import instagram from "../../assets/img/daCrazy/insta_icon.png";
import facebook from "../../assets/img/daCrazy/fb_icon.png";
import telegram from "../../assets/img/icons/telegram.svg";
import leftBg from "../../assets/img/daCrazy/left_bg_two.png";

import axios from "axios";
import { useMediaQuery } from "react-responsive";
const ContactArea = ({
  ClassItem = "contact_us_area section-padding-100-0",
  data,
}) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, SetIsLoading] = useState(false);
  const isMob = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      SetIsLoading(true);
      axios
        .post("https://api.indexx.ai/api/v1/inex/basic/emailToAdmin", {
          email,
          website: "wibs",
          message,
        })
        .then((response) => {
          setEmail("");
          setMessage("");
          SetIsLoading(false);
          console.log(response, "responnse");
        })
        .catch((error) => {
          SetIsLoading(false);
        });
    } catch (err) {
      SetIsLoading(false);
      console.log(err, "err inn subscribe form");
    }
  };

  return (
    <div
      className={ClassItem}
      style={{
        backgroundImage: "url(img/core-img/pattern1.png)",
        backgroundPosition: "center",
        backgroundSize: "100%",
        position: "relative",
      }}
    >
      {!isMob && (
        <>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: -100,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <img src={leftBg} style={{ width: "80%" }} />
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 2800,

              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <img src={rightBg2} style={{ width: "60%" }} /> */}
          </div>
        </>
      )}

      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionHeading
              ClassTitle="gradient-text blue"
              title={data.SectionHeading.title}
              otherTitle={data.SectionHeading.otherTitle}
              text={data.SectionHeading.text}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-60px",
                marginBottom: "50px",
              }}
              className="social-img"
            >
              <a
                href="https://x.com/dacrazyhawaiian?lang=en"
                style={{ marginLeft: "10px" }}
                target="_blank"
              >
                <img
                  src={twitter}
                  alt=""
                  width="53px"
                  style={{ filter: "invert(1)" }}
                />
              </a>
              <a
                href="https://www.instagram.com/da_crazy_hawaiian/?hl=en"
                style={{ marginLeft: "10px" }}
                target="_blank"
              >
                <img src={instagram} alt="Twitter" width="50px" />
              </a>
              <a
                href="https://www.facebook.com/DACRAZYHAWAIIANSLAPCHAMP/"
                style={{ marginLeft: "10px" }}
                target="_blank"
              >
                <img src={facebook} alt="Twitter" width="53px" />
              </a>
            </div>
          </div>
        </div>
        {/* Contact Form */}
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="contact_form">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div id="success_fail_info" />
                  </div>
                  {data?.list?.map((item, key) => (
                    <div className={item.ClassUp} key={key} data-aos="fade-up">
                      <div className="group" data-wow-delay="0.5s">
                        {item.addTextArea ? (
                          <textarea
                            name={item.nameInput}
                            id={item.nameInput}
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        ) : (
                          <input
                            type="text"
                            name={item.nameInput}
                            id={item.nameInput}
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        )}
                        <label>{item.title}</label>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 text-center" data-aos="fade-up">
                    <button
                      onClick={onSubmit}
                      className="btn more-btn black-btn"
                    >
                      {`${loading ? "submitting..." : data.send}`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactArea;
